import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ThemeProvider } from '@parspec/pixel';

import ReviewApp from './components/ReviewApp/ReviewApp';
import RedirectSubmittal from './components/RedirectSubmittal/RedirectSubmittal';
import NotFound from './components/NotFound/NotFound';
import { ResponseInterceptor } from './responseinterceptor';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false
        }
    }
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ThemeProvider>
                    <Routes>
                        <Route path="/:docType/:id" element={<ReviewApp />} />
                        <Route path="/view/:id" element={<RedirectSubmittal />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <ResponseInterceptor />
                </ThemeProvider>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    );
}

export default App;
