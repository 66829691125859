import { useEffect, useRef, useState, useCallback } from 'react';

import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';

import { Box, BodySmall, useMediaQuery, FormatListBulletedIcon, ImageIcon, Theme } from '@parspec/pixel';

import { getProxyUrl } from '../../utils/utils';
import { LEFT_PANEL_TYPE } from './shared/constants';
import PdfLeftNavSkeleton from './PdfLeftNavSkeleton';
import { WEBVIEWER_FILENAME } from '../../shared/constants/constants';

interface PdfViewerProps {
    docUrl: string;
    isDownloadEnabled: boolean;
}

interface BookType {
    title: string;
    page: number;
}

function addCanvasThumbnail(pageNumber: any, canvas: any, docViewer: any, thumbnailContainer: any) {
    const ele = document.createElement('div');
    ele.classList.add('thumbnail-box');
    canvas.classList.add('markup-canvas');

    const thumbnailBox = document.createElement('div');
    thumbnailBox.classList.add('position-relative');
    thumbnailBox.appendChild(canvas);

    const footer = document.createElement('div');
    footer.classList.add('thumbnail-footer');

    const pageNum = document.createElement('span');

    pageNum.innerHTML = 'Page ' + pageNumber;
    footer.appendChild(pageNum);

    ele.appendChild(thumbnailBox);
    ele.appendChild(footer);

    canvas.onclick = () => {
        docViewer.setCurrentPage(pageNumber, true);
    };
    thumbnailContainer.appendChild(ele);
}

export default function PdfViewer({ docUrl, isDownloadEnabled }: PdfViewerProps) {
    let [isDocumentLoading, toggleIsDocumentLoading] = useState(false);
    const [currentLeftPanelType, changeCurrentLeftPanelType] = useState(LEFT_PANEL_TYPE.THUMBNAIL);
    const [book, setBook] = useState<Array<BookType>>([]);
    const viewRef = useRef<HTMLDivElement | null>(null);
    const webViewerRef = useRef<any>(null);

    const belowMaxTabletDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('md'));

    function updateBookMarks(doc: Core.Document) {
        doc.getBookmarks().then((bookmarks) => {
            const printOutlineTree = (item: Core.Bookmark) => {
                const name = item.getName();

                return { title: name, page: item.getPageNumber() };
            };

            const updatedBookmarkList = bookmarks.map(printOutlineTree);
            setBook(updatedBookmarkList);
        });
    }

    const setHeaderItems = useCallback(
        function (instance: WebViewerInstance) {
            let searchButtonElem = {};
            instance.UI.setHeaderItems((header: any) => {
                const items = header.getItems().filter((item: Record<string, any>) => {
                    if (item.dataElement === 'zoomOverlayButton') {
                        return true;
                    }
                    if (item.dataElement === 'searchButton') {
                        searchButtonElem = { ...item };
                    }
                    return false;
                });
                items.unshift({ type: 'spacer' });
                items.push({ type: 'spacer' });
                if (isDownloadEnabled && !belowMaxTabletDims) {
                    const printButton = {
                        type: 'actionButton',
                        img: 'icon-header-print-fill',
                        onClick: () => {
                            instance.UI.print();
                        },
                        dataElement: 'printButton',
                        hidden: ['tablet', 'mobile']
                    };
                    items.push(printButton);
                }
                items.push(searchButtonElem);
                header.update(items);
            });
        },
        [isDownloadEnabled, belowMaxTabletDims]
    );

    useEffect(() => {
        if (viewRef.current && docUrl && !webViewerRef.current) {
            toggleIsDocumentLoading(true);
            WebViewer(
                {
                    licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
                    path: `/${WEBVIEWER_FILENAME}/lib`,
                    initialDoc: getProxyUrl(docUrl),
                    useDownloader: false,
                    disabledElements: [
                        'selectToolButton',
                        'toolbarGroup-Edit',
                        'toolsHeader',
                        'panToolButton',
                        'toggleNotesButton',
                        'notesPanel',
                        'menuButton',
                        'highlightToolGroupButton',
                        'underlineToolGroupButton',
                        'strikeoutToolGroupButton',
                        'stickyToolGroupButton',
                        'freeHandToolGroupButton',
                        'toolsOverlay',
                        'viewControlsButton',
                        'leftPanelButton',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Shapes',
                        'squigglyToolGroupButton',
                        'annotationPopup',
                        'freeTextToolGroupButton',
                        'ribbons',
                        'header',
                        'contextMenuPopup'
                    ]
                },
                viewRef.current
            ).then((instance) => {
                webViewerRef.current = instance;
                const { documentViewer } = instance.Core;
                if (!belowMaxTabletDims) {
                    instance.UI.enableElements(['header']);
                    setHeaderItems(instance);
                }
                documentViewer.on('documentLoaded', async () => {
                    toggleIsDocumentLoading(false);
                    instance.UI.closeElements(['loadingModal']);
                    instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
                    const doc = documentViewer.getDocument();
                    updateBookMarks(doc);
                });
            });
        } else if (docUrl && webViewerRef.current) {
            toggleIsDocumentLoading(true);
            webViewerRef.current.UI.loadDocument(getProxyUrl(docUrl));
        }
        // added so that this useEffect only execute when docUrl changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docUrl]);

    useEffect(() => {
        if (webViewerRef.current) {
            const instance = webViewerRef.current;
            setHeaderItems(instance);
        }
    }, [setHeaderItems]);

    useEffect(() => {
        if (webViewerRef.current && !isDocumentLoading) {
            const trialAsync = async () => {
                const { documentViewer } = webViewerRef.current?.Core;
                const doc = documentViewer.getDocument();
                const pageCount = doc.getPageCount();
                const thumbnailContainer: any = document.getElementById('thumbnails-container');
                thumbnailContainer.innerHTML = '';

                for (let i = 0; i < pageCount; i++) {
                    const pageNumber = i + 1;

                    await new Promise<void>((resolve) => {
                        doc.loadThumbnailAsync(pageNumber, (canvas: any) => {
                            addCanvasThumbnail(pageNumber, canvas, documentViewer, thumbnailContainer);
                        });
                        resolve();
                    });
                }
            };
            if (currentLeftPanelType === LEFT_PANEL_TYPE.THUMBNAIL) trialAsync();
        }
    }, [currentLeftPanelType, isDocumentLoading]);

    function showThumbnail() {
        changeCurrentLeftPanelType(LEFT_PANEL_TYPE.THUMBNAIL);
    }

    function showBookmark() {
        changeCurrentLeftPanelType(LEFT_PANEL_TYPE.BOOKMARK);
    }

    function handleBookMarkClick(val: BookType) {
        return () => {
            const { docViewer } = webViewerRef?.current || {};
            if (docViewer) {
                docViewer.setCurrentPage(val.page, true);
            }
        };
    }

    return (
        <Box display="flex" width="100%" flex="1" overflow="auto">
            {!belowMaxTabletDims && (
                <Box display="flex" flexDirection="column" bgcolor="secondary.light" overflow="auto">
                    <Box display="flex" alignItems="flex-start" width="max-content" paddingLeft={2} marginTop={2}>
                        <Box height="29px" width="29px" boxSizing="border-box" p={1} bgcolor={currentLeftPanelType === LEFT_PANEL_TYPE.THUMBNAIL ? 'rgba(255,255,255,0.1)' : ''} borderRadius="4px">
                            <ImageIcon onClick={showThumbnail} sx={{ cursor: 'pointer', color: 'secondary.contrastText' }}></ImageIcon>
                        </Box>

                        <Box
                            height="29px"
                            width="29px"
                            boxSizing="border-box"
                            p={1}
                            ml={3}
                            bgcolor={currentLeftPanelType === LEFT_PANEL_TYPE.BOOKMARK ? 'rgba(255,255,255,0.1)' : ''}
                            borderRadius="4px"
                        >
                            <FormatListBulletedIcon onClick={showBookmark} sx={{ cursor: 'pointer', color: 'secondary.contrastText' }}></FormatListBulletedIcon>
                        </Box>
                    </Box>
                    <Box flex="1">
                        <Box width="184px" padding={2}>
                            {isDocumentLoading && <PdfLeftNavSkeleton leftPanelType={currentLeftPanelType} />}
                            <Box
                                id="thumbnails-container"
                                display={!isDocumentLoading && currentLeftPanelType === LEFT_PANEL_TYPE.THUMBNAIL ? 'block' : 'none'}
                                sx={{
                                    overflowY: 'auto',
                                    '.thumbnail-box': {
                                        padding: '8px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                    '.thumbnail-box canvas.markup-canvas': {
                                        cursor: 'pointer',
                                        width: '100% !important',
                                        height: '220px !important'
                                    },
                                    '.thumbnail-footer': {
                                        color: 'secondary.contrastText',
                                        fontSize: '12px'
                                    }
                                }}
                            />
                            {!isDocumentLoading && currentLeftPanelType === LEFT_PANEL_TYPE.BOOKMARK && (
                                <Box id="bookmark">
                                    {book.map((val) => (
                                        <BodySmall
                                            key={`${val.title}-key`}
                                            mt={4}
                                            p={2}
                                            color="secondary.contrastText"
                                            limit
                                            lines={1}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                                    color: 'secondary.contrastText',
                                                    borderRadius: '4px'
                                                }
                                            }}
                                            onClick={handleBookMarkClick(val)}
                                        >
                                            {val.title}
                                        </BodySmall>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}

            <Box ref={viewRef} flex="1" height="100%"></Box>
        </Box>
    );
}
