import { Grid, IconButton, Button, Box, useMediaQuery, Theme, LinkIcon, DownloadIcon, SelectChangeEvent } from '@parspec/pixel';

import VersionSelect from './VersionSelect/VersionSelect';
import { VersionType } from './shared/types';
import VersionChange from './VersionChange/VersionChange';
import ParspecLogo from '../../shared/ParspecLogo/ParspecLogo';

interface HeaderProps {
    currentVersion: string;
    versionList?: Array<VersionType>;
    versionNotes?: string;
    onVersionChange?: (event: SelectChangeEvent<unknown>) => void;
    onDownload?: () => void;
    onCopyLink: () => void;
    isDownloadEnabled: boolean;
}

const iconBtnCss = {
    width: '24px',
    height: '24px'
};

const iconContainerCss = {
    xs: '8px',
    sm: '12px',
    md: '8px'
};

export default function Header({ currentVersion, versionList, onCopyLink: handleCopyLink, versionNotes, onVersionChange: handleVersionChange, isDownloadEnabled, onDownload }: HeaderProps) {
    const belowMaxTabletDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('md'));
    const belowMaxMobileDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
    const showLogoText = !belowMaxMobileDims;
    const logoSize = !belowMaxTabletDims ? 'small' : belowMaxMobileDims ? 'medium' : 'large';
    const isLatestVersion = versionList?.[0]?.value === currentVersion;

    function handleDownload() {
        if (isDownloadEnabled && onDownload) {
            onDownload();
        }
    }

    return (
        <>
            <Grid
                container
                padding={{
                    xs: 5,
                    sm: '28px 36px',
                    md: 4
                }}
                paddingTop={{
                    md: 6
                }}
                rowSpacing={{ xs: 5, sm: 7, md: 4 }}
            >
                <Grid item container alignItems="center" justifyContent="space-between" xs={12}>
                    <Grid
                        item
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width={{
                            md: '446px'
                        }}
                    >
                        <ParspecLogo size={logoSize} showText={showLogoText} />
                        {!belowMaxTabletDims && versionList && (
                            <VersionSelect currentVersion={currentVersion} onChange={handleVersionChange!} options={versionList} isLatestVersion={isLatestVersion} />
                        )}
                    </Grid>
                    <Grid item display="flex">
                        <Box marginRight={isDownloadEnabled ? iconContainerCss : undefined}>
                            {!belowMaxTabletDims ? (
                                <IconButton sx={iconBtnCss} onClick={handleCopyLink}>
                                    <LinkIcon />
                                </IconButton>
                            ) : (
                                <Button startIcon={<LinkIcon />} onClick={handleCopyLink} color="secondary" variant="outlined">
                                    Copy URL
                                </Button>
                            )}
                        </Box>
                        {isDownloadEnabled && (
                            <Box>
                                {!belowMaxTabletDims ? (
                                    <IconButton sx={iconBtnCss} onClick={handleDownload}>
                                        <DownloadIcon />
                                    </IconButton>
                                ) : (
                                    <Button startIcon={<DownloadIcon />} onClick={handleDownload} color="tertiary">
                                        Download
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {belowMaxTabletDims && versionList && (
                    <Grid item xs={12} paddingTop={{ xs: 7, sm: 9 }}>
                        <VersionSelect currentVersion={currentVersion} onChange={handleVersionChange!} options={versionList} isLatestVersion={isLatestVersion} />
                    </Grid>
                )}
                {versionNotes && (
                    <Grid item xs={12}>
                        <VersionChange text={versionNotes} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
