import { Box, Skeleton, useMediaQuery, Theme } from '@parspec/pixel';

export default function ReviewSkeleton() {
    const belowMaxTabDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('md'));
    const belowMaxMobDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
    return (
        <Box
            width="100%"
            height="100vh"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            p={belowMaxTabDims ? (belowMaxMobDims ? 5 : 9) : 0}
            pt={belowMaxTabDims && !belowMaxMobDims ? 7 : undefined}
        >
            <Skeleton
                variant="rectangular"
                width="100%"
                height={belowMaxTabDims ? (belowMaxMobDims ? '100px' : '111.5px') : '58px'}
                sx={{
                    marginTop: '8px',
                    marginBottom: belowMaxTabDims ? (belowMaxMobDims ? 5 : 9) : '5px'
                }}
                animation="wave"
            />
            <Box width="100%" height="calc(100% - 76px)" flex="1" display="flex" justifyContent="space-between">
                {!belowMaxTabDims && <Skeleton variant="rectangular" width="200px" height="100%" animation="wave" />}
                <Skeleton variant="rectangular" width={belowMaxTabDims ? '100%' : 'calc(100% - 205px)'} height="100%" animation="wave" />
            </Box>
        </Box>
    );
}
