import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { authAndfetchDocument, endPoints, getDocumentInfo, logDocViewEvent, logDownloadDocumentEvent, validateDocument } from './api';
import { DocViewerResponse } from './apiTypes';

interface AuthAndFetchArgsType {
    shareId: string;
    docType: string;
    body: { email?: string; password?: string };
}

export const getShareDocumentKey = (...args: [string, string]) => [endPoints.getDocumentInfoApi(...args)];

export const useAuthAndFetchDoc = () => {
    const client = useQueryClient();
    return useMutation(({ shareId, body, docType }: AuthAndFetchArgsType) => authAndfetchDocument(docType, shareId, body), {
        onSuccess: (response, variables) => {
            const { docType, shareId } = variables;
            client.setQueryData<AxiosResponse<DocViewerResponse, any>>(getShareDocumentKey(docType, shareId), (oldData) => {
                if (oldData) {
                    return {
                        ...oldData,
                        data: {
                            ...oldData?.data,
                            ...response.data
                        }
                    };
                }

                return {
                    ...response
                };
            });
        }
    });
};

export const useGetDocInfo = (docType: string, shareId: string, options?: { enabled?: boolean }) =>
    useQuery({
        queryKey: getShareDocumentKey(docType, shareId),
        queryFn: () => getDocumentInfo(docType || '', shareId || ''),
        ...options
    });

export const useLogViewEvent = () =>
    useMutation(({ shareId, versionId, docType, body }: { shareId: string; versionId: string; docType: string; body: { email: string } }) => logDocViewEvent({ docType, shareId, versionId }, body));

export const useLogDownloadDocumentEvent = () =>
    useMutation(({ shareId, versionId, docType, body }: { shareId: string; versionId: string; docType: string; body: { email: string } }) =>
        logDownloadDocumentEvent({ docType, shareId, versionId }, body)
    );

export const useValidateDocument = () => useMutation(({ docType, shareId }: { docType: string; shareId: string }) => validateDocument(docType, shareId));
