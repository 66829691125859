import { axios } from '../../../axios';
import { DocViewerResponse } from './apiTypes';

interface UrlInfoType {
    docType: string;
    shareId: string;
    versionId: string;
}
interface EventApiPayloadType {
    email: string;
}

export const endPoints = {
    getDocumentInfoApi(docType: string, shareId: string) {
        return `/doc_viewer/${docType}/${shareId}`;
    },
    getAuthAndFetchDocApi(docType: string, shareId: string) {
        return `/doc_viewer/${docType}/${shareId}`;
    },
    getDownloadEventApi(docType: string, shareId: string, versionId: string) {
        return `/doc_viewer/${docType}/${shareId}/${versionId}/download`;
    },
    getDocViewEventApi(docType: string, shareId: string, versionId: string) {
        return `/doc_viewer/${docType}/${shareId}/${versionId}/view`;
    },
    getDocumentValidateUrl(docType: string, shareId: string) {
        return `/doc_viewer/${docType}/${shareId}/validate`;
    }
};

export function getDocumentInfo(docType: string, shareId: string) {
    const url = endPoints.getDocumentInfoApi(docType, shareId);
    return axios.get<DocViewerResponse>(url);
}

export function authAndfetchDocument(docType: string, shareId: string, body?: { email?: string; password?: string }) {
    const url = endPoints.getAuthAndFetchDocApi(docType, shareId);
    return axios.post<DocViewerResponse>(url, body);
}

export function logDownloadDocumentEvent(urlInfo: UrlInfoType, body: EventApiPayloadType) {
    const { docType, shareId, versionId } = urlInfo;
    const url = endPoints.getDownloadEventApi(docType, shareId, versionId);
    return axios.post(url, body);
}

export function logDocViewEvent(urlInfo: UrlInfoType, body: EventApiPayloadType) {
    const { docType, shareId, versionId } = urlInfo;
    const url = endPoints.getDocViewEventApi(docType, shareId, versionId);
    return axios.post(url, body);
}

export function validateDocument(docType: string, shareId: string) {
    const url = endPoints.getDocumentValidateUrl(docType, shareId);
    return axios.post(url);
}
