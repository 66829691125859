import { useEffect, useState, useMemo, useRef, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as FileSaver from 'file-saver';

import { Box, SelectChangeEvent, Snackbar } from '@parspec/pixel';

import Header from './Header';
import LoginModal from './LoginModal/LoginModal';
import PdfViewer from './PdfViewer';
import { useGetDocInfo, useLogViewEvent, useAuthAndFetchDoc, useLogDownloadDocumentEvent, useValidateDocument } from './queries/index';
import { VersionType } from './shared/types';
import { getProxyUrl } from '../../utils/utils';
import { getLoginCredsFromLs, clearLoginCredsFromLs, setLoginCredsToLs } from './shared/utils';
import ReviewSkeleton from './ReviewSkeleton';
import DisabledModal from './DisabledModal/DisabledModal';
import { DocType } from './shared/constants';
import { DocumentInfo } from './queries/apiTypes';

const SnackBar_Message = {
    DOWNLOADING: {
        msg: 'Downloading...',
        time: null
    },
    DOWNLOADED: {
        msg: 'Downloaded Successfully',
        time: 1000
    },
    COPY_LINK: {
        msg: 'Copied',
        time: 1000
    }
};
type SnackbarMsgKeyType = keyof typeof SnackBar_Message;
type SnackBarMsg = (typeof SnackBar_Message)[SnackbarMsgKeyType];

const visible = 'visible';

function getDateForString(dateString: string): { year: string; month: string; date: string } {
    const dateObj = new Date(dateString);
    const year = String(dateObj.getFullYear());
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const date = ('0' + dateObj.getDate()).slice(-2);

    return {
        year,
        month,
        date
    };
}

function getDocVersionListFromData(data: Array<Record<string, any>> | undefined): Array<VersionType> | undefined {
    if (!data || data.length === 1) {
        return;
    }

    return data.map((documentInfo, index) => {
        const versionId = documentInfo.id;
        const { year, date, month } = getDateForString(documentInfo.created_at);
        const dateString = `${year}/${month}/${date}`;
        const versionLabel = `Version ${documentInfo.version} - ${dateString} (${index === 0 ? 'Latest' : 'Superseded'})`;
        return {
            label: versionLabel,
            value: versionId
        };
    });
}

function getDocVersionObj(data: Array<DocumentInfo> | undefined): Record<string, DocumentInfo> | undefined {
    if (!data) {
        return;
    }

    const documentObj: Record<string, DocumentInfo> = data.reduce((acc, documentInfo) => {
        const versionId: string = documentInfo.id;
        acc[versionId] = {
            ...documentInfo
        };

        return acc;
    }, {} as Record<string, DocumentInfo>);

    return documentObj;
}

export default function ReviewApp() {
    const [hasUserLoggedIn, toggleHasUserLoggedIn] = useState(false);
    const [currentVersion, changeCurrentVersion] = useState('');
    const [toFetchDocInfo, updateToFetchDocInfo] = useState(false);
    const [currentSnackBar, changeSnackBar] = useState<SnackBarMsg | null>(null);
    const [showSnackBar, toggleSnackBar] = useState(false);

    const loginCredsRef = useRef<Record<string, any>>({});
    const downloadAnchorRef = useRef<HTMLAnchorElement>(null);
    const eventLog = useRef<Record<string, any>>({});
    const downloadRef = useRef(false);

    const { id: shareId, docType } = useParams();
    // react query hooks
    const { isLoading: isAuthAndFetchInProgress, mutateAsync: login, error: authAndFetchError, reset: resetAuthAndFetch } = useAuthAndFetchDoc();

    const {
        isInitialLoading: isGetDocInfoInProgress,
        data: responseData,
        error: getDocInfoError,
        isRefetching: isGetDocInfoRefetchInProgress,
        refetch: refetchDocInfo
    } = useGetDocInfo(docType!, shareId!, { enabled: !!shareId && !!docType && toFetchDocInfo });
    const { mutateAsync: logViewEvent } = useLogViewEvent();
    const { mutateAsync: logDocDownloadEvent } = useLogDownloadDocumentEvent();
    const { mutateAsync: validateDocument, error: validateShareLinkError, reset: resetValidateDocument } = useValidateDocument();

    // All derived states
    const showLoginPopUp = !hasUserLoggedIn && Boolean(responseData?.data) && !responseData?.data?.documents;
    const docArray = responseData?.data?.documents;
    const projectName = responseData?.data?.project_name || '';
    const branchLocation = responseData?.data?.company_name || '';
    const isDownloadEnabled = responseData?.data?.can_download || false;
    const isPasswordEnabled = (responseData?.data?.password?.length && responseData?.data?.password?.length > 0) || false;
    const versionList = useMemo(() => getDocVersionListFromData(docArray), [docArray]);
    const versionInfoObj = useMemo(() => getDocVersionObj(docArray), [docArray]);
    const currentVersionInfo = versionInfoObj?.[currentVersion];
    const docUrl = currentVersionInfo?.document_link_flat;
    const versionNotes = currentVersionInfo?.version_notes;
    const fileName = currentVersionInfo?.flat_filename;

    const getLsCredsInfo = useCallback(() => {
        const { docViewId, accessData, submittalId } = getLoginCredsFromLs();
        let isDocViewIdSameAsLs = docViewId === shareId;
        if (docType === DocType.SUBMITTAL) {
            isDocViewIdSameAsLs = isDocViewIdSameAsLs || submittalId === shareId;
        }
        const areAuthCredsSaved = docViewId && accessData && isDocViewIdSameAsLs && accessData.email;
        return { areAuthCredsSaved, accessData };
    }, [docType, shareId]);

    const handleWindowFocus = useCallback(
        (_event: Event) => {
            if (document.visibilityState === visible) {
                const { accessData, areAuthCredsSaved } = getLsCredsInfo();
                if (areAuthCredsSaved) {
                    const requestBody = { shareId: shareId!, docType: docType!, body: accessData! };
                    login(requestBody)
                        .then(() => {
                            resetValidateDocument();
                        })
                        .catch((error: any) => {
                            const { response: { status = 0 } = {} } = error;
                            eventLog.current = {};
                            if (status !== 404) {
                                updateToFetchDocInfo(true);
                                refetchDocInfo();
                                resetAuthAndFetch();
                                toggleHasUserLoggedIn(false);
                                clearLoginCredsFromLs();
                                resetValidateDocument();
                            }
                        });
                } else {
                    updateToFetchDocInfo(true);
                    refetchDocInfo();
                }
            }
        },
        [docType, login, refetchDocInfo, resetAuthAndFetch, shareId, resetValidateDocument, getLsCredsInfo]
    );

    useEffect(() => {
        document.addEventListener('visibilitychange', handleWindowFocus);
        document.addEventListener('focus', handleWindowFocus);

        return () => {
            document.removeEventListener('focus', handleWindowFocus);
            document.removeEventListener('visibilitychange', handleWindowFocus);
        };
    }, [handleWindowFocus]);

    useEffect(() => {
        if (shareId && docType) {
            const { accessData, areAuthCredsSaved } = getLsCredsInfo();
            if (areAuthCredsSaved) {
                const requestBody = { shareId, docType, body: accessData! };
                loginCredsRef.current.email = accessData?.email;
                loginCredsRef.current.password = accessData?.password;
                login(requestBody)
                    .then((_response: any) => {
                        toggleHasUserLoggedIn(true);
                        resetAuthAndFetch();
                    })
                    .catch((error: any) => {
                        const { response: { status = 0 } = {} } = error;
                        if (status !== 404) {
                            updateToFetchDocInfo(true);
                            clearLoginCredsFromLs();
                            resetAuthAndFetch();
                        }
                    });
            } else {
                updateToFetchDocInfo(true);
            }
        }
        // dependency array has values to deal with exhaustive dependency, point of this useEffect is to execute once after mount
    }, [shareId, docType, login, resetAuthAndFetch, getLsCredsInfo]);

    // Being used to set dropdown value/current version to latest version when post doc viewer api response is received
    useEffect(() => {
        if (responseData?.data?.documents) {
            const documentsList = responseData?.data?.documents;
            const isCurrentVersionValid = documentsList.find((document) => document?.id === currentVersion);
            if (!isCurrentVersionValid) {
                const latestVersion = documentsList?.[0]?.id || '';
                changeCurrentVersion(latestVersion);
            }
        }
        // added so that this useEffect only execute when responseData changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData]);

    useEffect(() => {
        if (currentVersion && shareId && docType && currentVersionInfo && !eventLog.current[currentVersion]) {
            const reqBody = { email: loginCredsRef.current.email };
            const eventInfo = { shareId, versionId: currentVersionInfo.version, docType, body: reqBody };
            logViewEvent(eventInfo);
            eventLog.current[currentVersion] = true;
        }
    }, [currentVersion, currentVersionInfo, shareId, docType, logViewEvent]);

    function handleLoginSuccess(email: string, password?: string) {
        toggleHasUserLoggedIn(true);
        loginCredsRef.current.email = email;
        loginCredsRef.current.password = password;
        if (shareId) {
            setLoginCredsToLs({ email, password }, shareId);
        }
    }

    function handleCopyLink() {
        changeSnackBar(SnackBar_Message.COPY_LINK);
        toggleSnackBar(true);
        navigator.clipboard.writeText(window.location.href);
    }

    function handleVersionChange(event: SelectChangeEvent<unknown>) {
        const newVersion: string = event.target?.value as string;
        if (newVersion) {
            changeCurrentVersion(newVersion);
        }
    }

    async function handleDownload() {
        if (downloadRef.current) {
            return;
        }

        try {
            toggleSnackBar(true);
            changeSnackBar(SnackBar_Message.DOWNLOADING);
            downloadRef.current = true;
            await validateDocument({ docType: docType!, shareId: shareId! });
            const downloadUrl = getProxyUrl(docUrl || '');
            const blob = await fetch(downloadUrl).then((res) => res.blob());
            if (downloadAnchorRef.current) {
                FileSaver.saveAs(blob, fileName);
            }
            // to cover edge case of other tooltip being used while downloading
            toggleSnackBar(true);
            changeSnackBar(SnackBar_Message.DOWNLOADED);
            // log download event
            const reqBody = { email: loginCredsRef.current.email };
            const downloadEventInfo = { shareId: shareId!, docType: docType!, versionId: currentVersionInfo?.version || '', body: reqBody };
            logDocDownloadEvent(downloadEventInfo);
        } catch (error) {
            console.error(error);
            toggleSnackBar(false);
        }
        downloadRef.current = false;
    }

    function handleSnackbarClose(_event: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        toggleSnackBar(false);
    }

    if (
        (authAndFetchError && authAndFetchError instanceof AxiosError && authAndFetchError?.response?.status === 404) ||
        (getDocInfoError && getDocInfoError instanceof AxiosError && getDocInfoError?.response?.status === 404) ||
        (validateShareLinkError && validateShareLinkError instanceof AxiosError && validateShareLinkError?.response?.status === 404)
    ) {
        return <DisabledModal open />;
    }

    if (isGetDocInfoInProgress || (isAuthAndFetchInProgress && !hasUserLoggedIn) || isGetDocInfoRefetchInProgress) {
        return <ReviewSkeleton />;
    }

    return (
        <>
            <Snackbar
                open={showSnackBar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={currentSnackBar?.time}
                onClose={handleSnackbarClose}
                message={currentSnackBar?.msg}
            />
            <LoginModal open={showLoginPopUp} onSuccess={handleLoginSuccess} shareId={shareId} projectName={projectName} branchLocation={branchLocation} isPasswordEnabled={isPasswordEnabled} />
            {!showLoginPopUp && (
                <Box width="100%" height="100vh" visibility={showLoginPopUp ? 'hidden' : 'visible'} display="flex" flexDirection="column">
                    <Header
                        onVersionChange={handleVersionChange}
                        currentVersion={currentVersion}
                        onCopyLink={handleCopyLink}
                        versionNotes={versionNotes}
                        versionList={versionList}
                        isDownloadEnabled={isDownloadEnabled}
                        onDownload={handleDownload}
                    />
                    <PdfViewer docUrl={docUrl || ''} isDownloadEnabled={isDownloadEnabled} />
                    <a
                        href="_blank"
                        style={{
                            display: 'none'
                        }}
                        ref={downloadAnchorRef}
                    >
                        Wrapper Anchor
                    </a>
                </Box>
            )}
        </>
    );
}
