import { Base64 } from 'js-base64';

export function getProxyUrl(url: string) {
    return `${process.env.REACT_APP_PROXY_URL}${Base64.encode(url)}`;
}

export function getLocalStorageItem(key: string) {
    return localStorage.getItem(key);
}

export function setLocalStorageItem(key: string, value: string) {
    localStorage.setItem(key, value);
}

export function deleteLocalStorageItem(key: string) {
    localStorage.removeItem(key);
}

export function validateEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}
