import { Info, BodyMedium, BodyBig, useMediaQuery, Theme } from '@parspec/pixel';

interface VersionChangeProps {
    text: string;
}

export default function VersionChange({ text }: VersionChangeProps) {
    const belowMaxMobileDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
    const TextComponent = belowMaxMobileDims ? BodyMedium : BodyBig;
    return (
        <Info width="calc(100% - 16px)">
            <TextComponent color="primary.main" fontWeight={600} limit lines={2}>
                Here’s what changed from the previous version of this document:
            </TextComponent>
            <TextComponent color="primary.main" limit lines={3}>
                {text}
            </TextComponent>
        </Info>
    );
}
