import { getLocalStorageItem, setLocalStorageItem, deleteLocalStorageItem } from '../../../utils/utils';

const ReviewAppLsConstants = {
    SUBMITTAL_ID: 'submittalId',
    ACCESS_DATA: 'accessData',
    DOC_VIEW_ID: 'docViewId'
};

export function getLoginCredsFromLs(): { accessData: { email: string; password?: string } | null; submittalId: string | null; docViewId: string | null } {
    let accessData: { email: string; password?: string } | string | null = getLocalStorageItem(ReviewAppLsConstants.ACCESS_DATA);
    if (accessData) {
        accessData = JSON.parse(accessData);
    }
    const submittalId = getLocalStorageItem(ReviewAppLsConstants.SUBMITTAL_ID);
    const docViewId = getLocalStorageItem(ReviewAppLsConstants.DOC_VIEW_ID);

    return { accessData: accessData as { email: string; password?: string } | null, submittalId, docViewId };
}

export function setLoginCredsToLs(accessData: { email: string; password?: string }, docViewId: string) {
    const serializedAccessData = JSON.stringify(accessData);
    setLocalStorageItem(ReviewAppLsConstants.ACCESS_DATA, serializedAccessData);
    setLocalStorageItem(ReviewAppLsConstants.DOC_VIEW_ID, docViewId);
}

export function clearLoginCredsFromLs() {
    deleteLocalStorageItem(ReviewAppLsConstants.ACCESS_DATA);
    deleteLocalStorageItem(ReviewAppLsConstants.DOC_VIEW_ID);
}
