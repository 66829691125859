import { Box, Skeleton } from '@parspec/pixel';

import { LEFT_PANEL_TYPE } from '../shared/constants';
import { LEFT_PANEL_TYPE_VALUE } from '../shared/types';

interface PdfLeftNavSkeletonProps {
    leftPanelType: LEFT_PANEL_TYPE_VALUE;
}

function ThumbnailSkeleton() {
    return (
        <Box>
            {[1, 1, 1].map((elem, index) => (
                <Box p={2} mb={4} key={`${elem}${index}`}>
                    <Skeleton variant="rectangular" width="168px" height="220px" sx={{ backgroundColor: 'rgba(255,255,255,0.1)' }} />
                </Box>
            ))}
        </Box>
    );
}

function BookmarkThumbnail() {
    return (
        <Box>
            {[1, 1, 1, 1, 1, 1].map((elem, index) => (
                <Box mt={4} key={`${elem}${index}`}>
                    <Skeleton variant="rounded" height="32px" sx={{ backgroundColor: 'rgba(255,255,255,0.1)' }} />
                </Box>
            ))}
        </Box>
    );
}

export default function PdfLeftNavSkeleton({ leftPanelType }: PdfLeftNavSkeletonProps) {
    if (leftPanelType === LEFT_PANEL_TYPE.BOOKMARK) {
        return <BookmarkThumbnail />;
    }
    return <ThumbnailSkeleton />;
}
