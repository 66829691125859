import { useState } from 'react';

import { BodySmall, Box, ModalHeader, Modal, BodyMedium, Snackbar } from '@parspec/pixel';

import { axios } from './axios';

export const ResponseInterceptor = () => {
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const handleDialog = () => {
        setOpenErrorModal((old) => !old);
    };
    axios.interceptors.response.use(
        (response) => {
            setOpenErrorModal(false);
            return response;
        },
        (error) => {
            switch (error.response?.status) {
                case 400:
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    setOpenErrorModal(true);
                    break;
                default:
                    setShowSnackbar(true);
                    break;
            }
            return Promise.reject(error);
        }
    );

    return (
        <>
            {openErrorModal && (
                <Modal open={openErrorModal} header={<ModalHeader title="Houston... we’ve got a problem." onClose={handleDialog} />}>
                    <Box width="417px">
                        <Box margin={'8px 0px 24px'} textAlign={'center'}>
                            <img src="/images/error/astronaut.svg" alt="astronaut" />
                        </Box>
                        <BodyMedium
                            sx={{
                                marginBottom: '16px',
                                height: '121px',
                                div: {
                                    WebkitLineClamp: 'unset !important'
                                }
                            }}
                        >
                            Looks like an unforeseen issue. Our support and engineering team will be notified to resolve this issue as soon as possible.
                            <br /> <br />
                            In the meantime here are some troubleshooting tips:
                        </BodyMedium>
                        <BodySmall
                            sx={{
                                backgroundColor: '#e0e8ff',
                                padding: '8px',
                                color: '#6467f2',
                                height: '80px',
                                lineHeight: '21px',
                                div: {
                                    WebkitLineClamp: 'unset !important'
                                }
                            }}
                        >
                            1. Trigger a hard refresh (Ctrl + Shift + R)
                            <br />
                            2. Open Parspec in a Google Incognito tab
                            <br />
                            3. If the issue persists, message us in the chatbot
                            <br />
                        </BodySmall>
                    </Box>
                </Modal>
            )}
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                message="Something went wrong, please try again!"
                onClose={() => {
                    setShowSnackbar(false);
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
            />
        </>
    );
};
