import { FormEvent, MouseEvent, useReducer, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Modal, Grid, Box, BodyXS, TextField, BodyMedium, BodyBig, BodySmall, useMediaQuery, Theme } from '@parspec/pixel';

import LoginModalFooter from './LoginModalFooter';
import { useAuthAndFetchDoc } from '../queries';
import ParspecLogo from '../../../shared/ParspecLogo/ParspecLogo';
import { validateEmail } from '../../../utils/utils';

interface LoginModalProps {
    open: boolean;
    projectName: string;
    branchLocation: string;
    isPasswordEnabled?: boolean;
    onSuccess: (email: string, password?: string) => void;
    shareId?: string;
}

interface TextFieldStateType {
    value: string;
    isValid: boolean;
}

interface TextFieldStateUpdateType {
    value?: string;
    isValid?: boolean;
}

function textFieldReducer(currState: TextFieldStateType, newState: TextFieldStateUpdateType) {
    return {
        ...currState,
        ...newState
    };
}

const textfieldDefaultValue = {
    value: '',
    isValid: true
};

export default function LoginModal({ open, projectName, branchLocation, isPasswordEnabled = false, onSuccess }: LoginModalProps) {
    const [email, changeEmail] = useReducer(textFieldReducer, { ...textfieldDefaultValue });
    const [password, changePassword] = useReducer(textFieldReducer, { ...textfieldDefaultValue });
    const [errorMessage, changeErrorMessage] = useState('');

    const { isLoading, mutateAsync: login } = useAuthAndFetchDoc();

    const { id: shareId, docType } = useParams();

    const belowMaxMobileDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
    const LoginInfoMessage = belowMaxMobileDims ? BodyXS : BodyBig;

    function handleTextfieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name } = event.target;
        if (name === 'password' || name === 'email') {
            const changeMethod = name === 'password' ? changePassword : changeEmail;
            changeMethod({
                value: event.target.value || '',
                isValid: true
            });
            changeErrorMessage('');
        }
    }

    function validateFields(): boolean {
        const emailVal = email.value.trim() || '';
        const passwordVal = password.value;
        let areFieldsValid = true;
        const isEmailValid = validateEmail(emailVal);
        if (!isEmailValid || (isPasswordEnabled && !passwordVal)) {
            areFieldsValid = false;
        }

        return areFieldsValid;
    }

    async function handleSubmit(event: MouseEvent | FormEvent) {
        event.preventDefault();
        if (shareId && docType) {
            const areFieldsValid = validateFields();
            if (!areFieldsValid) {
                const errorMessage = 'Please enter valid values';
                changeErrorMessage(errorMessage);
                return;
            }
            try {
                const emailVal = email.value.trim();
                const body = {
                    email: emailVal,
                    password: password.value
                };
                await login({ shareId, body, docType });
                onSuccess(emailVal, password.value);
            } catch (error: any) {
                const { response: { status = 0, data = '' } = {} } = error;
                const errorMessage = data?.message;
                if ((status === 401 || status === 400) && errorMessage) {
                    changeErrorMessage(errorMessage);
                    changeEmail({
                        isValid: false
                    });
                    changePassword({
                        isValid: false
                    });
                }
            }
        }
    }

    return (
        <Modal
            open={open}
            header={
                <Box
                    display="flex"
                    justifyContent="center"
                    padding={{
                        xs: '24px 0 8px',
                        sm: '40px 0 16px'
                    }}
                >
                    <ParspecLogo size="large" />
                </Box>
            }
            footer={<LoginModalFooter onSubmit={handleSubmit} isLoading={isLoading} />}
        >
            <form id="loginForm">
                <Grid
                    container
                    maxWidth={{
                        xs: '330px',
                        sm: '450px'
                    }}
                    flexDirection="column"
                    rowSpacing={{
                        xs: '16px',
                        sm: '24px'
                    }}
                >
                    <Grid item>
                        <BodyMedium limit lines={3}>
                            <Box component="span" fontWeight="600">
                                {`${branchLocation}`}
                            </Box>
                            {` shared a document for `}
                            <Box component="span" fontWeight="600">
                                {`“${projectName}”`}
                            </Box>
                            {` with you.`}
                        </BodyMedium>
                    </Grid>
                    <Grid item>
                        <LoginInfoMessage limit lines={2}>
                            Please enter your information to continue.
                        </LoginInfoMessage>
                    </Grid>
                    <Grid item>
                        <TextField label="Email Address" name="email" value={email.value || null} onChange={handleTextfieldChange} size="medium" autoFocus />
                    </Grid>
                    {isPasswordEnabled && (
                        <Grid item>
                            <TextField label="Password" name="password" type="password" value={password.value || null} onChange={handleTextfieldChange} size="medium" />
                        </Grid>
                    )}
                    {errorMessage && (
                        <Grid item>
                            <BodySmall color="error">{errorMessage}</BodySmall>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Modal>
    );
}
