import { Box, Select, SelectChangeEvent } from '@parspec/pixel';

import { VersionType } from '../shared/types';

interface VersionSelectProps {
    currentVersion: string;
    onChange: (event: SelectChangeEvent<unknown>) => void;
    options: VersionType[];
    isLatestVersion: boolean;
}

export default function VersionSelect({ currentVersion, onChange: handleChange, options, isLatestVersion }: VersionSelectProps) {
    const selectBorderColor = isLatestVersion ? 'success' : 'error';
    return (
        <Box
            height={{
                xs: '36px'
            }}
            width={{
                xs: '100%',
                md: '275px'
            }}
        >
            <Select
                borderColor={selectBorderColor}
                label="Version"
                labelId="version"
                id="version"
                onChange={handleChange}
                optionLabelKeyname="label"
                optionValueKeyname="value"
                options={options}
                value={currentVersion}
                fullWidth
            />
        </Box>
    );
}
