import { BodyBig, Box, H4, Link } from '@parspec/pixel';

import ParspecLogo from '../../shared/ParspecLogo/ParspecLogo';

const url = 'https://parspec.io/';
export default function NotFound() {
    return (
        <Box color="primary.main" width="100%" height="100vh" textAlign="center" display="flex" flexDirection="column" justifyContent="center">
            <Box p={2}>
                <Box width="max-content" margin="0 auto">
                    <ParspecLogo size="large" showText={false} />
                </Box>
                <H4 limit lines={2}>
                    Sorry, this page isn't available
                </H4>
                <BodyBig limit lines={2}>
                    The link may have been broken or removed. Go back to <Link href={url}>Parspec</Link>
                </BodyBig>
            </Box>
        </Box>
    );
}
