import { Box, H4, H5, ParspecLogoIcon } from '@parspec/pixel';

interface ParspecLogoProps {
    size: 'small' | 'medium' | 'large';
    showText?: boolean;
}

const parspecLogoTextCssObj = {
    fontWeight: '800'
};

function getLogoSize(size: string) {
    switch (size) {
        case 'small':
            return '28px';
        case 'medium':
            return '40px';
        case 'large':
            return '44px';
    }
}

function getHeaderComponent(size: string) {
    switch (size) {
        case 'small':
            return H5;
        case 'large':
            return H4;
        case 'medium':
        default:
            return H5;
    }
}

export default function ParspecLogo({ showText = true, size }: ParspecLogoProps) {
    const logoSize = getLogoSize(size);
    const Title = getHeaderComponent(size);

    return (
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    '& svg': {
                        fontSize: logoSize
                    }
                }}
            >
                <ParspecLogoIcon color={'tertiary'} />
            </Box>
            {showText && (
                <Title marginLeft="9px" sx={parspecLogoTextCssObj}>
                    PARSPEC
                </Title>
            )}
        </Box>
    );
}
