import { Button, Box } from '@parspec/pixel';

interface LoginModalFooterProps {
    onSubmit: React.MouseEventHandler;
    isLoading: boolean;
}

export default function LoginModalFooter({ onSubmit, isLoading }: LoginModalFooterProps) {
    return (
        <Box>
            <Button onClick={onSubmit} isLoading={isLoading} size="large" fullWidth type="submit" form="loginForm">
                Access Document
            </Button>
        </Box>
    );
}
