import { Modal, Box, H5, BodySmall, useMediaQuery, Theme } from '@parspec/pixel';

import ParspecLogo from '../../../shared/ParspecLogo/ParspecLogo';

interface DisabledModalProps {
    open: boolean;
}

export default function DisabledModal({ open }: DisabledModalProps) {
    const belowMaxTabDims = useMediaQuery((theme: Theme) => theme.breakpoints?.down('md'));
    return (
        <Modal open={open}>
            <Box display="flex">
                <ParspecLogo size="large" showText={false} />
                <Box marginLeft="20px">
                    <H5
                        sx={{
                            fontWeight: '600'
                        }}
                        limit
                        lines={belowMaxTabDims ? 2 : 1}
                    >
                        Sorry! We are unable to open this document.
                    </H5>
                    <BodySmall margin="14px 0px" limit lines={belowMaxTabDims ? 2 : 1}>
                        This link does not exist or was disabled.
                    </BodySmall>
                </Box>
            </Box>
        </Modal>
    );
}
